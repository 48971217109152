<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Reglas de correos electronicos</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                <div class="input-group">
                  <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-outline-primary">Buscar</button>
                  </div>
                </div>                
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <button v-on:click="$router.push({ name: 'CreateReglaEmail', params: {add: true} });" class="btn btn-primary"><i data-feather="plus-circle"></i>Crear regla</button>
            </div>
            <br>
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="list-doc" role="tabpanel" aria-labelledby="list-doc-list">
                <div class="table-responsive">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center">ID de la solicitud</th>
                        <th>Condiciones</th>
                        <th>Descripción</th>
                        <th>Dependencia</th>
                        <th>Serie</th>
                        <th class="text-center">Acciones</th>
                        <th class="text-center">
                          <input type="checkbox" v-model="allChecked" v-on:click="checkAllItems">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, index) in items">
                        <tr :key="'main-row-' + item._id" :dataReglas="item">
                          <td class="text-center">
                            <strong> {{ item.rgm_cdgo }} </strong>
                          </td>
                          <td>
                            <span v-for="(condicion, i) in item.condiciones" :key="'condition-' + i">
                              {{ getCampoNombre(condicion.campo) }} <strong><u>{{ getOperadorNombre(condicion.operador) }}</u></strong>  {{ condicion.valor }}<br>
                            </span>
                          </td>
                          <td>{{ item.rgm_descripcion }}</td>
                          <td :class="{'text-muted': !getDependenciaNameById(item.dep_id) || getDependenciaNameById(item.dep_id) === 'No se radica'}">
                            {{ getDependenciaNameById(item.dep_id) }}
                          </td>
                          <td :class="{'text-muted': !getSerieNameById(item.ser_id) || getSerieNameById(item.ser_id) === 'No se radica'}">
                            {{ getSerieNameById(item.ser_id) }}
                          </td>
                          <td class="text-center">
                            <v-icon class="cursor-pointer" @click="openEditModal(item)">mdi-pencil</v-icon>
                          </td>
                          <td class="text-center">
                            <input
                              type="checkbox"
                              v-model="delItems" 
                              :value="item._id"
                              v-if="item.cmp_id !== '000000000000000000000000'"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="list-doc-archivo" role="tabpanel" aria-labelledby="list-archivo-list">...</div>
            </div>
            <br>
            <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
              <i data-feather="delete"></i>Eliminar
            </button>
            <!--{{delItems}}-->
          </div>
        </div>
      </div>
    </div>
    <ModalForm :visible="show" @close="show=false" :message="'Por favor espere mientras se consultan los datos..'" />
    
    <div v-if="editModalVisible" class="modal-edit-reglas">
      <div class="modal-content">
        <h2>Editar Regla</h2>
        <form @submit.prevent="updateRegla">
          <div class="modal-form">
            <div class="form-group">
              <label>ID de la solicitud</label>
              <input type="text" v-model="editForm.rgm_cdgo" class="form-control" disabled />
            </div>
            <div class="form-group">
              <label>Descripción</label>
              <input type="text" v-model="editForm.rgm_descripcion" class="form-control" />
            </div>
          </div>
          <div class="contenedor-condiciones">
            <h2 class="titulo-condiciones">Editar Condiciones</h2>

            <button type="button" class="btn-agregar-condiciones" @click="mostrarFormulario = !mostrarFormulario">
              <span v-if="mostrarFormulario"><i class="fas fa-plus"></i></span>
              <i v-else class="fas fa-plus"></i>
            </button>

            <table class="tabla-condiciones">
              <thead>
                <tr>
                  <th>Criterio de Clasificación</th>
                  <th>Operador</th>
                  <th>Campo de Evaluación</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(condiciones, index) in editForm.condiciones" :key="index">
                  <td>
                    <select v-model="condiciones.campo" class="input-tabla-condiciones">
                      <option value="from">Remitente (De)</option>
                      <option value="subject">Asunto</option>
                      <option value="text">Texto del mensaje</option>
                    </select>
                  </td>
                  <td>
                    <select v-model="condiciones.operador" class="input-tabla-condiciones">
                      <option value="equals">Igual a</option>
                      <option value="contains">Contiene</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" v-model="condiciones.valor" class="input-tabla-condiciones" />
                  </td>
                  <td>
                    <button type="button" class="btn-eliminar-condiciones" @click="eliminarCondicion(index)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="mostrarFormulario" class="formulario-condiciones">
              <label for="nuevo-campo" class="label-condiciones">Seleccione el atributo:</label>
              <select id="nuevo-campo" v-model="nuevaCondicion.campo" class="input-condiciones">
                <option value="" disabled>Seleccione el atributo del mensaje</option>
                <option value="from">Remitente (De)</option>
                <option value="subject">Asunto</option>
                <option value="text">Texto del mensaje</option>
              </select>

              <label for="nuevo-operador" class="label-condiciones">Seleccione el operador de comparación</label>
              <select id="nuevo-operador" v-model="nuevaCondicion.operador" class="input-condiciones">
                <option value="" disabled>Seleccione una opción</option>
                <option value="equals">Igual a</option>
                <option value="contains">Contiene</option>
              </select>

              <label for="nuevo-valor" class="label-condiciones">Texto a comparar:</label>
              <input type="text" id="nuevo-valor" v-model="nuevaCondicion.valor" class="input-condiciones" />

              <button type="button" class="btn-guardar-condiciones" @click="agregarCondicion">Guardar</button>
            </div>
          </div><br><br>
          <div>
            <div class="form-group">
              <label class="custom-checkbox">
                ¿Radicar automáticamente?
                <input type="checkbox" v-model="editForm.rgm_radicar">
                <span></span>
              </label>
            </div>
            <!-- Este es el resto del formulario -->
            <div v-if="editForm.rgm_radicar" class="modal-form">

              <div class="form-group">
                <label>Clase de documento (*):</label>
                <select v-model="editForm.tcr_id" class="form-control">
                  <option v-for="tcr in tpocors" :key="tcr.id" :value="tcr.id">{{ tcr.text }}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Tipo de documento (*):</label>
                <select v-model="editForm.tdo_id" class="form-control">
                  <option v-for="tpd in tpodocs" :key="tpd.id" :value="tpd.id">{{ tpd.text }}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Tipo de tabla (*):</label>
                <select v-model="editForm.tta_id" class="form-control">
                  <option v-for="tcr in tpotablas" :key="tcr.id" :value="tcr.id">{{ tcr.text }}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Versión de tabla (*):</label>
                <input id="tav_nmro" type="number" v-model="editForm.tav_nmro" class="form-control">
              </div>

              <div class="form-group">
                <label>Dependencia</label>
                <select v-model="editForm.dep_id" class="form-control">
                  <option v-for="dep in dependencias" :key="dep.id" :value="dep.id">{{ dep.dep_nmbre }}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Serie</label>
                <select v-model="editForm.ser_id" class="form-control">
                  <option v-for="ser_id in series" :key="ser_id.id" :value="ser_id.id">{{ ser_id.ser_nmbre }}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Tipo de archivo (*)</label>
                <select v-model="editForm.tpa_id" class="form-control">
                  <option v-for="dep in tpoarchs" :key="dep.id" :value="dep.id">{{ dep.text }}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Usuario (*)</label>
                <select v-model="editForm.usu_id" class="form-control">
                  <option v-for="dep in usuarios" :key="dep.id" :value="dep.id">{{ dep.text }}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Tiempo de respuesta (*):</label>
                <input type="number" v-model="editForm.dcm_tres" class="form-control">
              </div>

              <div class="form-group">
                <label class="custom-checkbox">
                  ¿Activar pendiente de respuesta?
                  <input type="checkbox" v-model="editForm.dcm_rspsta">
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </form>
        <br>
        <div class="form-group">
          <button type="submit" @click="updateRegla" class="btn btn-primary me-4">Guardar Cambios</button>
          <button type="button" class="btn btn-secondary" @click="closeEditModal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import ModalForm from "../modal/ModalForm.vue";
import { formatDateUtc } from '../../plugins/filters';

export default {
  components: {
    //VIcon,
    ModalForm,
  },
  data() {
    return {
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords: "",
      search: {},
      filter: false, // Items are filtered
      show: false,
      expandedIndex: null,
      series: [],
      dependencias: [],
      editModalVisible: false,
      editForm: {
        id: '',
        rgm_descripcion: '',
        dep_id: '',
        ser_id: '',
        tcr_id: '',
        condiciones: [],
      },
      mostrarFormulario: false,
      nuevaCondicion: { 
        campo: "", 
        operador: "", 
        valor: "" 
      },
      tpocors: [],
      tpodocs: [],
      tpotablas: [],
      tpoarchs: [],
      usuarios: [],
      dataReglas: '',
    };
  },
  filters: {
    formatDateUtc
  },
  computed: {
    checkedItems() {
      if (this.delItems.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function() {
    feather.replace();
    this.fetchSerie();
    this.fetchDependencia();
    this.fetchTpocor();
    this.fetchTpodoc();
    this.fetchTpotabla();
    this.fetchTpoarch();
    this.fetchUsuario();
  },
  created: function() {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.searchItems(this.search);
    } else {
      this.search = { lastYear: new Date().getFullYear() };
      this.searchItems(this.search);
    }
  },
  methods: {
    eliminarCondicion(index) {
      if (this.editForm.condiciones.length > 1) {
        this.editForm.condiciones.splice(index, 1);
      } else {
        alert("Debe haber al menos una condición.");
      }
    },
    agregarCondicion() {
      if (this.nuevaCondicion.campo && this.nuevaCondicion.operador && this.nuevaCondicion.valor) {
        this.editForm.condiciones.push({ ...this.nuevaCondicion });
        this.nuevaCondicion = { campo: "", operador: "", valor: "" };
        this.mostrarFormulario = false;
      }
    },
    getCampoNombre(campo) {
      return campo === 'from' ? 'Si remitente' :
            campo === 'subject' ? 'Si asunto' :
            campo === 'text' ? 'Si el texto del mensaje' : campo;
    },
    getOperadorNombre(operador) {
      return operador === 'equals' ? 'es igual a' :
            operador === 'contains' ? 'contiene el texto' : operador;
    },
    getSerieNameById(id) {
      const ser_id = this.series.find(s => s.id === String(id));
      return ser_id ? ser_id.ser_nmbre : 'No se radica';
    },
    getClaseDocNameById(id) {
      const tcr = this.tpocors.find(t => String(t.id).trim() === String(id).trim());
      return tcr ? tcr.text : ' ';
    },
    getTipoDocNameById(id) {
      const tdo = this.tpodocs.find(t => t.id === String(id));
      return tdo ? tdo.tdo_nmbre : 'No se radica';
    },
    getDependenciaNameById(id) {
      const dep = this.dependencias.find(d => d.id === String(id));
      return dep ? dep.dep_nmbre : 'No se radica';
    },
    deleteItems() {
      if (this.delItems.length > 0) {
        const response = confirm("Está seguro que desea eliminar los registros seleccionados?");
        if (response) {
          this.show = true;
          let uri = "/reglaemails/deleteitems";
          this.axios
            .post(uri, { delItems: this.delItems })
            .then((res) => {
              this.show = false;
              if (this.search) {
                this.searchItems(this.search);
              }
            })
            .catch((err) => {
              this.show = false;
              //alert(err.response.data.msg);
              if (err.response.data.i > 0) {
                if (this.search) {
                  this.searchItems(this.search);
                }
              }
            });
        }
      }
      return;
    },
    checkAllItems() {
      this.delItems = [];
      if (!this.allChecked) {
        for (var i = 0; i < this.items.length; i++) {
          if (this.items[i].cmp_id){
            this.delItems.push(this.items[i]._id);
          }
        }
      }
    },
    searchItems(p){
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
        this.message = 'Buscando Reglas';
        this.show = true;
        p.cmp_id = this.$store.state.company;
        let uri = '/reglaemails/search';
        this.axios.post(uri, p)
        .then(response => {
          this.items = response.data;
          this.search = p;
          this.delItems = [];
          this.filter = true;
          this.show = false;
        })
        .catch(err => {
          this.message = '¡Error al buscar los días no laborales! ' + err;
        });
      }
    },
    closeSearch() {
      this.filter = false;
      this.search = {};
      this.keywords = "";
      this.items = [];
    },
    newSearch () {
      this.search = {};
    },
    createItem() {
      this.$router.push({ name: "CreateReglaEmail", params: { add: true, search: this.search } });
    },
    editItem(id) {
      this.$router.push({ name: "CreateReglaEmail", params: { id: id, add: false, search: this.search } });
    },
    toggleDetails(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
    },
    fetchSerie(){
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.ser_nmbre + ' (' + a.ser_cdgo + ')').toLowerCase(); 
          var tb = String(b.ser_nmbre + ' (' + b.ser_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.series = list.map(i => ({ 
          id: String(i._id), 
          ser_nmbre: i.ser_nmbre,
          text: i.ser_nmbre + ' (' + i.ser_cdgo + ')' 
        }));
      });
    },
    fetchDependencia(){
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.dep_nmbre + ' (' + a.dep_cdgo + ')').toLowerCase(); 
          var tb = String(b.dep_nmbre + ' (' + b.dep_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.dependencias = list.map(i => ({ 
          id: String(i._id), 
          dep_nmbre: i.dep_nmbre,
          text: i.dep_nmbre + ' (' + i.dep_cdgo + ')' 
        }));
      });
    },
    openEditModal(item) {
      this.editForm = {
        rgm_cdgo: item.rgm_cdgo,
        id: item._id,
        rgm_radicar : item.rgm_radicar,
        rgm_descripcion: item.rgm_descripcion,
        dep_id: item.dep_id,
        ser_id: item.ser_id,
        condiciones: item.condiciones,
        tdo_id: item.tdo_id,
        tcr_id: item.tcr_id,
        dcm_tres: item.dcm_tres,
        dcm_rspsta: item.dcm_rspsta,
        tav_nmro: item.tav_nmro,
        tpa_id: item.tpa_id,
        tta_id: item.tta_id,
        usu_id: item.usu_id,
      };
      this.editModalVisible = true;
    },
    closeEditModal() {
      this.editModalVisible = false;
      this.searchItems(this.search);
    },
    updateRegla() {
      const uri = `/reglaemails/update/${this.editForm.id}`;

      // Construimos el objeto con los campos obligatorios
      let payload = {
        rgm_radicar: this.editForm.rgm_radicar,
        rgm_descripcion: this.editForm.rgm_descripcion,
        condiciones: this.editForm.condiciones,
      };

      // Si rgm_radicar es true, añadimos los demás campos
      if (this.editForm.rgm_radicar) {
        Object.assign(payload, {
          dep_id: this.editForm.dep_id,
          ser_id: this.editForm.ser_id,
          tdo_id: this.editForm.tdo_id,
          tcr_id: this.editForm.tcr_id,
          dcm_tres: this.editForm.dcm_tres,
          dcm_rspsta: this.editForm.dcm_rspsta,
          tav_nmro: this.editForm.tav_nmro,
          tpa_id: this.editForm.tpa_id,
          tta_id: this.editForm.tta_id,
          usu_id: this.editForm.usu_id,
        });
      }
      this.axios
        .post(uri, payload)
        .then(() => {
          this.editModalVisible = false;
          this.searchItems(this.search); 
        })
        .catch((err) => {
          console.error("Error al actualizar la regla:", err);
        });
    },
    fetchTpocor(){
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.tcr_nmbre + ' (' + a.tcr_cdgo + ')').toLowerCase(); 
          var tb = String(b.tcr_nmbre + ' (' + b.tcr_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.tpocors = list.map(i => ({ id: String(i._id), text: i.tcr_nmbre + ' (' + i.tcr_cdgo + ')' }));
      });
    },
    fetchTpodoc(){
      let uri = '/tpodocs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.tdo_nmbre + ' (' + a.tdo_cdgo + ')').toLowerCase(); 
          var tb = String(b.tdo_nmbre + ' (' + b.tdo_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.tpodocs = list.map(i => ({ id: String(i._id), text: i.tdo_nmbre + ' (' + i.tdo_cdgo + ')' }));
      });
    },
    fetchTpotabla(){
      let uri = '/tpotablas/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.tta_nmbre + ' (' + a.tta_cdgo + ')').toLowerCase(); 
          var tb = String(b.tta_nmbre + ' (' + b.tta_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.tpotablas = list.map(i => ({ id: String(i._id), text: i.tta_nmbre + ' (' + i.tta_cdgo + ')' }));
      });
    },
    fetchTpoarch(){
      let uri = '/tpoarchs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.tpa_nmbre + ' (' + a.tpa_cdgo + ')').toLowerCase(); 
          var tb = String(b.tpa_nmbre + ' (' + b.tpa_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.tpoarchs = list.map(i => ({ id: String(i._id), text: i.tpa_nmbre + ' (' + i.tpa_cdgo + ')' }));
      });
    },
    
    async fetchUsuario(){
      try {
        let uri = '/usuarios/list';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        var response = await this.axios.post(uri, { cmp_id: String(this.$store.state.company) });        
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.usu_nmbre + ' ' + a.usu_aplldo + ' (' + a.usu_cdgo + ')').toLowerCase(); 
          var tb = String(b.usu_nmbre + ' ' + b.usu_aplldo + ' (' + b.usu_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.usuarios = list.map(i => ({ id: String(i._id), text: i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }));    
      } catch(err) {
        console.log(err);
      }
    },
  },
};
</script>

<style>
.text-muted {
  color: rgba(0, 0, 0, 0.5); 
  font-style: italic; 
}

.modal-edit-reglas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%; /* Para pantallas pequeñas */
  max-width: 900px; /* Ancho máximo */
  max-height: 80vh; /* Limita la altura para que no sobrepase la pantalla */
  overflow-y: auto;
}
.modal-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columnas */
  gap: 15px; /* Espacio entre inputs */
}

.modal-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.contenedor-condiciones {
  max-width: 100%;
  margin: auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Título */
.titulo-condiciones {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #333;
}

/* Tabla */
.tabla-condiciones {
  width: 100%;
  border-collapse: collapse;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tabla-condiciones th, .tabla-condiciones td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tabla-condiciones thead {
  background-color: #f8f9fa;
  font-weight: bold;
}

.tabla-condiciones tbody tr:hover {
  background: #f1f3f5;
}

/* Inputs en la tabla */
.input-tabla-condiciones {
  width: 100%;
  padding: 6px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;
}

.input-tabla-condiciones:focus {
  border-color: #007bff;
}

/* Botones de la tabla */
.btn-editar-condiciones {
  color: #28a745;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
}

.btn-eliminar-condiciones {
  color: #dc3545;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
}

.btn-editar-condiciones:hover, .btn-eliminar-condiciones:hover {
  opacity: 0.7;
}

.btn-agregar-condiciones {
  width: auto; 
  height: 36px;
  padding: 5px 10px; /* Espaciado interno */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  left: 0; 
}

.btn-agregar-condiciones:hover {
  background: #0056b3;
}


/* Formulario de agregar condición */
.formulario-condiciones {
  margin-top: 16px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Labels */
.label-condiciones {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  display: block;
  margin-top: 8px;
  text-align: left;
}

/* Inputs en el formulario */
.input-condiciones {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;
}

.input-condiciones:focus {
  border-color: #007bff;
}

/* Botón de guardar en el formulario */
.btn-guardar-condiciones {
  margin-top: 12px;
  width: 100%;
  padding: 10px;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}

.btn-guardar-condiciones:hover {
  background: #218838;
}

</style>